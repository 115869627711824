<template>
  <div>
        <b-jumbotron class="mt-4">
            <template #header>Lista transport</template>
            <hr class="my-4">
            <p>
                Acțiuni:
            </p>
            <b-button variant="light" to="/car/0">Adaugă transport</b-button>
        </b-jumbotron>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>Model</th>
          <th>Culoare</th>
          <th>Număr înmatriculare</th>
          <th>Creat</th>
          <th>
            <router-link to="/car/0" class="btn btn-sm btn-success">
              <vue-fontawesome icon="plus"></vue-fontawesome> Adaugă
            </router-link>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in data" :key="'r-'+index">
          <td>{{ row.id }}</td>
          <td>{{ row.model }}</td>
          <td>{{ row.color }}</td>
          <td>{{ row.number }}</td>
          <td>{{ row.created_at }}</td>
          <td>
            <router-link :to="'/car/' + row.id" class="btn btn-sm btn-primary">
              <vue-fontawesome icon="edit"></vue-fontawesome> Modifică
            </router-link>
            <button @click="remove(row.id)" class="btn btn-sm btn-danger ml-3">
              <vue-fontawesome icon="trash"></vue-fontawesome> Șterge
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'CarsList',
  mounted () {
    this.getData()
  },
  data () {
    return {
      data: []
    }
  },
  computed: {
    apiUrl () {
      return config.baseApiUrl + '/api/cars'
    }
  },
  methods: {
    getData () {
      this.$axios.get(this.apiUrl)
        .then((response) => {
          this.data = response.data.data
        })
    },
    remove (id) {
      if (confirm('Remove?')) {
        this.$axios.delete(config.baseApiUrl + '/api/cars/' + id)
          .then(() => {
            this.getData()
          })
      }
    }
  }
}
</script>

<style scoped>
  .table tr th,
  .table tr td {
    vertical-align: middle;
  }
</style>
